import Image from 'next/image'
import { ShoppingCartItem as ShoppingCartItemModel } from 'models/Product'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { ImageContainer } from './ShoppingCartItem.styles'
import { formatCurrency } from 'utils/currency'
import { Link } from 'components/Link'

export const ShoppingCartItem = ({ product }: { product: ShoppingCartItemModel }) => {
  return (
    <Stack justifyContent="space-between" alignItems="center">
      <Stack>
        <ImageContainer>
          <Image src={product.image} objectFit="contain" alt="" layout="fill" />
        </ImageContainer>

        <Stack direction="column">
          <Link href={`/products/${product.slug}`} passHref>
            <Text as="a" weight="medium" color="accent-black" css={{ maxWidth: '152px' }}>
              {product.name}
            </Text>
          </Link>
          <Text color="accent-black" size="small">{`Qty: ${product.quantity}`}</Text>
        </Stack>
      </Stack>
      <Stack direction="column">
        {product.price.discount ? (
          <>
            <Text weight="medium" color="primary-500" lineThrough>
              {formatCurrency(product.price.subtotal)}
            </Text>
            <Text weight="medium" color="primary-500">
              {formatCurrency(product.price.total)}
            </Text>
          </>
        ) : (
          <Text color="primary-500">
            <Text weight="medium" display="block">
              {formatCurrency(product.price.total)}
            </Text>
            <Text>/unit</Text>
          </Text>
        )}
      </Stack>
    </Stack>
  )
}
