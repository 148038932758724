import { styled } from 'stitches.config'

export const NutritionClaimRoot = styled('div', {
  width: '100%',
  backgroundColor: '$primary-700',
  padding: '$7 $3',

  '@bp2': {
    padding: '$8 $4',
  }
})

export const Container = styled('div', {
  maxWidth: '500px',
  margin: '0 auto',

  '@bp2': {
    maxWidth: '1440px',
  }
})

export const Content = styled('div', {
  maxWidth: '380px',
  margin: '0 auto',

  '@bp2': {
    maxWidth: '980px',
  },

  '@bp3': {
    maxWidth: '1100px',
  }
})