import { Badge } from 'components/Badge'
import { Button } from 'components/Button'
import { Dialog } from 'components/Dialog'
import { Hr } from 'components/Hr'
import { Icon } from 'components/Icon'
import { Link } from 'components/Link'
import { Popover } from 'components/Popover'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { VerticalSeparator } from 'components/VerticalSeparator'
import { useShoppingCartSidebarProvider } from 'contexts/ShoppingCartSidebarContext'
import { useFetchShoppingCart } from 'features/ECommerce/api/queries/useFetchShoppingCart'
import { useRouter } from 'hooks/useRouter'
import { useTranslation } from 'hooks/useTranslation'
import Image from 'next/image'
import { ComponentProps, ReactNode, useEffect, useMemo, useState, forwardRef } from 'react'
import { CookieKeys, getCookie, removeCookie } from 'services/Cookies'
import logo from '../../../public/assets/company-name.svg'

import { TranslationKeys } from '../../locales/translationKeys'
import {
  DialogWrapper,
  HeaderContent,
  HeaderRoot,
  Isotype,
  Logo,
  MainNav,
  MainNavItem,
  MobileMenuButton,
  PopoverItem,
  PopoverList,
  PopoverTrigger,
  UserSectionNav,
  UserSectionNavItem,
  IconWrapper,
} from './Header.styles'
import { MobileHeaderMenu } from './components/MobileHeaderMenu'
import { useNavFlex } from 'hooks/useNavFlex'

type subsection = {
  readonly label: TranslationKeys
  readonly url: string
}

type Link = {
  readonly label: TranslationKeys
  readonly url: string
  readonly subsections?: subsection
}

const recipeSections = [
  { label: 'header:recipes.beef', url: '/recipes/beef' },
  { label: 'header:recipes.chicken', url: '/recipes/chicken' },
  { label: 'header:recipes.lamb', url: '/recipes/lamb' },
  { label: 'header:recipes.duck', url: '/recipes/duck' },
  { label: 'header:recipes.turkey', url: '/recipes/turkey' },
  { label: 'header:recipes.venison', url: '/recipes/venison' },
] as Link[]

const mainSections = [
  { label: 'header:whyWeFeedRaw', url: '/why-we-feed-raw' },
  { label: 'header:ourStory', url: '/our-story' },
  { label: 'header:recipes.main', url: '/recipes', subsections: recipeSections },
  { label: 'header:allProducts', url: '/products' },
  { label: 'header:reviews', url: '/reviews' },
] as Link[]

const trimmedSections = [
  { label: 'header:whyWeFeedRaw', url: '/why-we-feed-raw' },
  { label: 'header:ourStory', url: '/our-story' },
  { label: 'header:recipes.main', url: '/recipes' },
  { label: 'header:reviews', url: '/reviews' },
] as Link[]

type HeaderOwnProps = ComponentProps<typeof HeaderRoot>
export type HeaderProps = HeaderOwnProps & {
  snapToTop?: boolean
  isMenuOpen?: boolean
  customCTA?: ReactNode
  trimmed?: boolean
  forceDesktop?: boolean
}

export type MyAccountLinkProps = {
  isLogged: boolean
  isMyAccountMenuOpen: boolean
  setIsMyAccountMenuOpen: (isMyAccountMenuOpen: boolean) => void
  setShowLogoutConfirmationModal: (showLogoutConfirmationModal: boolean) => void
  isMobileScreenMenu?: boolean
}

export type SubMenuProps = {
  isSubMenuOpen: boolean
  setIsSubMenuOpen: (isSubMenuOpen: boolean) => void
  isMobileScreenMenu?: boolean
}

export type LogoutModalProps = {
  showLogoutConfirmationModal: boolean
  setShowLogoutConfirmationModal: (showLogoutConfirmationModal: boolean) => void
}

export const Header = ({
  snapToTop = false,
  isMenuOpen = false,
  isFixed = false,
  customCTA,
  trimmed = false,
  forceDesktop = false,
  ...rest
}: HeaderProps) => {
  const { t } = useTranslation()

  const { pathname } = useRouter()
  const [isLogged, setIsLogged] = useState(false)
  const [isInTop, setIsInTop] = useState(snapToTop)
  const [isMyAccountMenuOpen, setIsMyAccountMenuOpen] = useState(false)
  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false)
  const [navReset, setNavReset] = useState(false)

  const {
    isMobile,
    navIsLoading,
    hideCTA,
    navRef,
    headerRef,
    userRef,
    logoRef,
    ctaRef,
    cartRef,
  } = useNavFlex({
    navReset,
    forceDesktop,
  })

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  const pageBlackList = ['/login', '/cart', '/user', '/meal-plan']
  const blackListed = pageBlackList.some((page) => pathname.includes(page))

  const menuLinks = trimmed ? trimmedSections : mainSections

  useEffect(() => {
    setIsLogged(Boolean(getCookie(CookieKeys.SESSION)))
  }, [])

  useEffect(() => {
    if (!window || !snapToTop) return

    const _scrollListener = () => {
      const currentScroll = document.querySelector('html')?.scrollTop || 0

      setIsInTop(currentScroll < 50)
    }

    window.addEventListener('scroll', _scrollListener)

    return () => window.removeEventListener('scroll', _scrollListener)
  }, [snapToTop])

  return (
    <HeaderRoot isInTop={isInTop} snapToTop={snapToTop} isFixed={isFixed} {...rest}>
      <HeaderContent ref={headerRef}>
        <Link href="/" passHref>
          <Stack
            as="a"
            title={t('header:logoLinkTitle')}
            alignItems="center"
            justifyContent="center"
          >
            <Logo ref={logoRef}>
              <Image layout="fixed" src={logo} alt="" loading="eager" />
            </Logo>
          </Stack>
        </Link>
        {isMobile && (
          <>
            <MobileHeaderMenu
              isMyAccountMenuOpen={isMyAccountMenuOpen}
              setIsMyAccountMenuOpen={setIsMyAccountMenuOpen}
              setShowLogoutConfirmationModal={setShowLogoutConfirmationModal}
              isLogged={isLogged}
              trigger={
                <MobileMenuButton
                  aria-label={isMenuOpen ? t('header:closeMenu') : t('header:openMenu')}
                >
                  <Icon color="accent-black" name="menu" />
                </MobileMenuButton>
              }
            />
            <div style={{ flex: '1' }}></div>
          </>
        )}
        {!isMobile && (
          <>
            <MainNav ref={navRef} aria-label={t('header:mainMenu')}>
              <Stack as="ul">
                {menuLinks.map(({ label, url, subsections }, index) => (
                  <MainNavItem key={index} active={pathname.includes(url)}>
                    {subsections ? (
                      <SubMenu isSubMenuOpen={isSubMenuOpen} setIsSubMenuOpen={setIsSubMenuOpen} />
                    ) : (
                      <Link href={url}>{t(label)}</Link>
                    )}
                  </MainNavItem>
                ))}
              </Stack>
            </MainNav>
          </>
        )}

        <UserSectionNav aria-label={t('header:userSectionMenu')}>
          <Stack as="ul" gap={{ '@initial': '1', '@bp2': '3' }} alignItems="center">
            {(isFixed || !hideCTA) && !blackListed && (
              <li>
                {customCTA ?? (
                  <Link href="/meal-plan" passHref>
                    <Button
                      ref={ctaRef}
                      css={{ whiteSpace: 'nowrap' }}
                      as="a"
                      className="header-cta"
                      size="small"
                      
                    >
                      {t('header:CTA')}
                    </Button>
                  </Link>
                )}
              </li>
            )}
            <UserSectionNavItem ref={userRef} active={pathname.includes('/user')}>
              <MyAccountLink
                isMyAccountMenuOpen={isMyAccountMenuOpen}
                setIsMyAccountMenuOpen={setIsMyAccountMenuOpen}
                isLogged={isLogged}
                setShowLogoutConfirmationModal={setShowLogoutConfirmationModal}
              />
            </UserSectionNavItem>
            {!trimmed && <ShoppingCartLink ref={cartRef} setNavReset={setNavReset} />}
          </Stack>
        </UserSectionNav>
      </HeaderContent>

      <LogoutModal
        showLogoutConfirmationModal={showLogoutConfirmationModal}
        setShowLogoutConfirmationModal={setShowLogoutConfirmationModal}
      />
    </HeaderRoot>
  )
}

Header.displayName = 'Header'

export const SubMenu = ({
  isSubMenuOpen,
  setIsSubMenuOpen,
  isMobileScreenMenu = false,
}: SubMenuProps) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()

  return (
    <Popover
      align="start"
      sideOffset={12}
      open={isSubMenuOpen}
      onOpenChange={() => setIsSubMenuOpen(!isSubMenuOpen)}
      trigger={
        <PopoverTrigger
          active={pathname.includes('/recipes')}
          isNavItem
          menuActive={isSubMenuOpen}
          isMobileScreenMenu={isMobileScreenMenu}
        >
          <Text>{t('header:recipes.main')}</Text>
          <IconWrapper>
            <Icon
              className={isSubMenuOpen ? 'rotate' : ''}
              role="button"
              aria-label={t('header:recipes.main')}
              name="arrow-down-line"
              color="accent-black"
            />
          </IconWrapper>
        </PopoverTrigger>
      }
    >
      <PopoverList>
        <Stack as="ul" direction="column">
          <PopoverItem active={pathname === '/recipes'} as="li">
            <Link href="/recipes" passHref>
              <Stack direction="row" alignItems="center" as="a">
                <Text color="accent-black">{t('header:recipes.overview')}</Text>
              </Stack>
            </Link>
          </PopoverItem>
          {recipeSections.map((recipe, index) => (
            <PopoverItem active={pathname.includes(recipe.url)} as="li" key={index}>
              <Link href={recipe.url} passHref>
                <Stack direction="row" alignItems="center" as="a">
                  <Text color="accent-black">{t(`${recipe.label}`)}</Text>
                </Stack>
              </Link>
            </PopoverItem>
          ))}
        </Stack>
      </PopoverList>
    </Popover>
  )
}

export const MyAccountLink = ({
  isLogged,
  isMyAccountMenuOpen,
  setIsMyAccountMenuOpen,
  setShowLogoutConfirmationModal,
  isMobileScreenMenu = false,
}: MyAccountLinkProps) => {
  const { t } = useTranslation()

  return (
    <Popover
      align="end"
      sideOffset={8}
      open={isMyAccountMenuOpen}
      onOpenChange={() => setIsMyAccountMenuOpen(!isMyAccountMenuOpen)}
      trigger={
        <PopoverTrigger menuActive={isMyAccountMenuOpen} isMobileScreenMenu={isMobileScreenMenu}>
          <Icon color="accent-black" role="button" aria-label={t('header:open-user-menu')} name="user" />
        </PopoverTrigger>
      }
    >
      <PopoverList>
        <Stack as="ul" direction="column">
          {!isLogged ? (
            <PopoverItem as="li">
              <Link href="/login" passHref>
                <Stack as="a">
                  <Icon color="accent-black" name="user" />
                  <Text>{t('header:login')}</Text>
                </Stack>
              </Link>
            </PopoverItem>
          ) : (
            <>
              <PopoverItem as="li">
                <Link href="/user" passHref>
                  <Stack as="a">
                    <Icon color="accent-black" name="user" />
                    <Text>{t('header:my-account')}</Text>
                  </Stack>
                </Link>
              </PopoverItem>

              <Hr />

              <PopoverItem>
                <PopoverTrigger onClick={() => setShowLogoutConfirmationModal(true)}>
                  <Stack>
                    <Icon color="accent-black" name="sign-out" />
                    <Text>{t('header:logout')}</Text>
                  </Stack>
                </PopoverTrigger>
              </PopoverItem>
            </>
          )}
        </Stack>
      </PopoverList>
    </Popover>
  )
}

type ShoppingCartLinkProps = {
  setNavReset?: React.Dispatch<React.SetStateAction<boolean>>
}

export const ShoppingCartLink = forwardRef<HTMLLIElement, ShoppingCartLinkProps>(
  ({ setNavReset }, ref) => {
    
  const { t } = useTranslation()
  const { setShowSidebar } = useShoppingCartSidebarProvider()

  const { data: shoppingCart } = useFetchShoppingCart({}, { retry: false, retryOnMount: false })

  const { pathname } = useRouter()
  const url = '/cart'

  const total = useMemo(() => {
    return (
      shoppingCart?.items.reduce((sum, item) => {
        return sum + item.quantity
      }, 0) || 0
    )
  }, [shoppingCart])

  useEffect(() => {
    if (shoppingCart && setNavReset) {
      setNavReset((prev) => !prev)
    }
  }, [shoppingCart, setNavReset])

  if (total === 0) return null

  return (
    <>
      <li>
        <VerticalSeparator size="5" color="accent-black" />
      </li>
      <UserSectionNavItem
        active={url === pathname}
        onMouseOver={() => setShowSidebar(true)}
        onClick={() => setShowSidebar(false)}
        ref={ref}
      >
        <Badge  label="" count={total} color="primary-500" placement="rightTop">
          <Link href={url}>
            <a aria-label={t('header:shoppingCart')} title={t('header:shoppingCart')}>
              <Icon color="accent-black" name="shopping-cart" css={{ display: 'flex' }} />
            </a>
          </Link>
        </Badge>
      </UserSectionNavItem>
    </>
  )
})

export const LogoutModal = ({
  showLogoutConfirmationModal,
  setShowLogoutConfirmationModal,
}: LogoutModalProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const logout = () => {
    removeCookie(CookieKeys.SESSION)
    router.push('/login')
    setShowLogoutConfirmationModal(false)
  }

  return (
    <Dialog open={showLogoutConfirmationModal} onOpenChange={setShowLogoutConfirmationModal}>
      <DialogWrapper>
        <Stack direction="column" gap="4">
          <Stack direction="column" gap="2">
            <Text size="headline" weight="medium" color="accent-black">
              {t('header:logout')}
            </Text>
            <Text weight="medium" color="accent-black">
              {t('header:logout-confirmation')}
            </Text>
          </Stack>
          <Stack direction="column" gap="3">
            <Button onClick={logout}> {t('header:logout')}</Button>
            <Button onClick={() => setShowLogoutConfirmationModal(false)} variant="tertiary">
              {t('header:cancel')}
            </Button>
          </Stack>
        </Stack>
      </DialogWrapper>
    </Dialog>
  )
}
