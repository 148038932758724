import {
  HeroRoot,
  DesktopImageColumn,
  MobileImageColumn,
  CtaColumn,
  Layout,
  ButtonWrapper,
  HeroHeader,
  ImageWrapper,
} from './Hero.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { Button } from 'components/Button'
import hero from '../../../../../public/assets/home/home-hero-preparing-meal.jpeg'
import heroMobile from '../../../../../public/assets/home/home-hero-preparing-meal-mobile.png'
import Link from 'next/link'

export const Hero = () => {
  const { t } = useTranslation()

  return (
    <HeroRoot role="banner">
      <Layout gap="0" direction={{ '@initial': 'column', '@bp2': 'row' }}>
        <MobileImageColumn>
          <Image
            objectFit="cover"
            objectPosition="0% 60%"
            height={266}
            width={500}
            src={heroMobile}
            alt={t('home:hero.image1')}
            loading="eager"
            priority
          />
        </MobileImageColumn>
        <DesktopImageColumn>
          <ImageWrapper>
            <Image
              objectFit="cover"
              layout="fill"
              src={hero}
              alt={t('home:hero.image1')}
              loading="eager"
              priority
            />
          </ImageWrapper>
        </DesktopImageColumn>
        <CtaColumn justifyContent="center" direction="column" alignItems="center" gap="2">
          <Stack direction="column" alignItems="flex-start" gap="2">
            <HeroHeader>
              <Stack display={{ '@initial': 'none', '@bp3': 'flex' }} direction="column" gap="0">
                <Text font="display" size="h1" color="accent-black">
                  {t('home:hero.title')}
                </Text>
              </Stack>
              <Stack display={{ '@initial': 'flex', '@bp3': 'none' }}>
                <Text font="display" size="h3" color="accent-black">
                  {t('home:hero.title-m')}
                </Text>
              </Stack>
            </HeroHeader>

            <Stack display={{ '@initial': 'none', '@bp2': 'flex' }} direction="column" gap="0">
              <Text  size="body" color="accent-black">
                {t('home:hero.subtitle2')}
              </Text>
              <Text  size="body" color="accent-black">
                {t('home:hero.subtitle3')}
              </Text>
              <Text  size="body" color="accent-black">
                {t('home:hero.subtitle4')}
              </Text>
            </Stack>
            <Stack display={{ '@initial': 'block', '@bp2': 'none' }}>
              <Text  size="body" color="accent-black">
                {t('home:hero.subtitle-m')}
              </Text>
            </Stack>
            <ButtonWrapper>
              <Link href="/meal-plan">
                <Button as="a" className="cta-hero">
                  {t('home:hero.CTA')}
                </Button>
              </Link>
            </ButtonWrapper>
          </Stack>
        </CtaColumn>
      </Layout>
    </HeroRoot>
  )
}

Hero.displayName = 'Hero'
