import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'

export const ReviewsRoot = styled('div', {
  width: '100%',
  backgroundColor: '$secondary-500',
  textAlign: 'center',
  padding: '$5 0 $5 0',
  
  '@bp2': {
    padding: '$10 $8 $8 $8'
  }
})

export const ReviewsContainer = styled('div', {
  maxWidth: '500px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@bp2': {
    maxWidth: '1440px',
  }
})

export const ReviewCard = styled('div', {
    backgroundColor: '$accent-white',
    margin: 'auto',
    width: '100%',
    padding: '$3 $4',
    borderRadius: '22px',
    border: '1px solid $primary-700',
    '@bp2': {
        padding: '$4 $6',
    }
})

export const Title = styled(Text, {
    margin: 'auto',
    marginBottom: '$4',
    width: '100%',
    maxWidth: '260px',
    '@bp2': {
        maxWidth: 'none',
    }
})

export const ButtonWrapper = styled('div', {
    paddingTop: '$12',

    '@bp2': {
        paddingTop: '$13',
    }
})

export const SliderContainer = styled('div', {
    display: 'block',
    margin: 'auto',
    width: '100%',
    maxWidth: '400px',
    zIndex: 0,
    '& .slick-next': {
      height: 0,
      width: 0,
    },
  
    '& .slick-prev': {
      height: 0,
      width: 0,
    },
  
    '& .slick-list': {
      zIndex: 1,
    },
  
    '& .slick-dots': {
      bottom: '-50px',
      zIndex: 2,
    },
  
    '& .slick-dots li': {
        width: '10px',
        height: '10px',
        margin: '0 8px',
      },
    
      '& .slick-dots li button': {
        width: '10px',
        height: '10px',
        padding: 0,
      },
    
      '& .slick-dots li button:before': {
        content: '""',
        width: '10px',
        height: '10px',
        border: '2px solid $accent-white',
        borderRadius: '50%',
        opacity: 1,
        backgroundColor: 'transparent',
      },
    
      '& .slick-dots li.slick-active button:before': {
        backgroundColor: '$accent-white',
        opacity: 1,
      },
  
    '@bp2': {
      maxWidth: '634px',
  
      '& .slick-next': {
        height: '80px',
        width: '80px',
        right: '-120px',
      },
  
      '& .slick-prev': {
        height: '80px',
        width: '80px',
        left: '-120px',
      },
    },
  })

  export const ReviewContainer = styled('div', {
    display: 'flex !important',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    padding: '0 $2',

    '@bp2': {
      padding: '0 $4',
    }
  })