import { useEffect, useState } from 'react'
import { useDiscountCode } from '../../contexts/DiscountContext'
import { CookieKeys, getCookie } from '../../services/Cookies'
import { Storage, StorageKeys } from '../../services/Storage'
import { SessionKeys, SessionStorage } from '../../services/Session'
import { CloseButton, DiscountBannerRoot } from './DiscountBanner.styles'
import { DefaultBanner } from './DiscountBannerTypes/Default/DefaultBanner'
import { BlackFridayBanner } from './DiscountBannerTypes/BlackFriday/BlackFridayBanner'
import { PromotionalBanner } from './DiscountBannerTypes/Promotion/PromotionalBanner'

type DiscountBannerProps = {
  pathName: string
  startDate: Date
  endDate: Date
  defaultCode: string
}

export const DiscountBanner = ({
  pathName,
  startDate,
  endDate,
  defaultCode,
}: DiscountBannerProps) => {
  const [isHidden, setIsHidden] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [displayText, setDisplayText] = useState('')
  const [displayAmount, setDisplayAmount] = useState('')
  const [displayFreeCopy, setDisplayFreeCopy] = useState('')
  const { discountCodeData: data, isLoading, refetchDiscountCode: refetch } = useDiscountCode()

  const code = Storage.get(StorageKeys.PROMO_CODE) ?? ''

  //pages banner will display
  const allowedPages =
    pathName.length === 1 ||
    [
      '/meal-plan/step-1',
      '/meal-plan/step-2',
      '/meal-plan/step-3',
      '/meal-plan/step-4',
      '/recipes',
      '/recipes/beef',
      '/recipes/lamb',
      '/recipes/chicken',
      '/recipes/turkey',
      '/recipes/duck',
      '/recipes/venison',
    ].some((page) => pathName.includes(page))

  //pages that are not allowed to click on the banner which will link to the meal plan
  const disableClickPages = [
    '/meal-plan/step-1',
    '/meal-plan/step-2',
    '/meal-plan/step-3',
    '/meal-plan/step-4',
  ].some((page) => pathName.includes(page))

  useEffect(() => {
    if (Boolean(SessionStorage().getItem(SessionKeys.BANNER_HIDE))) {
      setIsHidden(true)
    }
    setIsLoggedIn(Boolean(getCookie(CookieKeys.SESSION)))
    if (!data) return

    if (!data.isActive) {
      Storage.set(StorageKeys.PROMO_CODE, defaultCode)
      refetch()
      return
    }

    setDisplayText(data.displayText)
    setDisplayAmount(data.displayAmount)
    setDisplayFreeCopy(data.displayFreeCopy)
  }, [data, refetch])

  useEffect(() => {
    if (!isHidden) return
    SessionStorage().setItem(SessionKeys.BANNER_HIDE, 'true')
  }, [isHidden])

  if (!displayAmount || isLoading || !allowedPages || isLoggedIn) return null

  const now = new Date()
  const beforeBlackFridayEnds = now <= endDate

  //allows for early access BF 50% discounts to appear on the banner
  const isPercentage50 =
    data?.type === 'PERCENTAGE' && data?.amount === 50 && data.isActive && beforeBlackFridayEnds

  //which banner to show
  const discountType =
    isPercentage50 || blackFridayDiscounts.includes(code)
      ? 'blackFriday'
      : percentPlusItemDiscounts.includes(code)
      ? 'promotion'
      : 'default'

  return (
    <DiscountBannerRoot type={discountType} css={isHidden ? { display: 'none' } : {}}>
      {discountType === 'blackFriday' ? (
        <BlackFridayBanner disableClickPages={disableClickPages} displayAmount={displayAmount} />
      ) : discountType === 'promotion' ? (
        <PromotionalBanner disableClickPages={disableClickPages} displayAmount={displayAmount} />
      ) : (
        <DefaultBanner
          code={code}
          displayText={displayText}
          displayAmount={displayAmount}
          displayFreeCopy={displayFreeCopy}
          disableClickPages={disableClickPages}
          treatsForOneYear={treatsForOneYear}
        />
      )}
      <CloseButton hoverColor="red" aria-label="Close" onClick={() => setIsHidden(true)}>
        x
      </CloseButton>
    </DiscountBannerRoot>
  )
}
DiscountBanner.displayName = 'DiscountBanner'

const blackFridayDiscounts = ['50WAG']
const percentPlusItemDiscounts = ['WESTPAW']
const treatsForOneYear = ['TREATS730', 'FUSARO801']
